import { graphql } from "gatsby"
import * as React from "react"
import { renderMetaTags, StructuredText } from "react-datocms"
import Template from "../../components/templates/template"
import { BLOG_SLUG } from "../../constants/constants"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, Trans } from "gatsby-plugin-react-i18next"
import ArrowLeft from "../../icons/arrow-left.svg"
import ArrowRight from "../../icons/arrow-right.svg"
import Theme from "../../components/theme"
import ImagesGallery from "../../components/organisms/sections/imagesGallery"

const Post = ({ data, pageContext }) => {
  const post = data.allDatoCmsPost.edges.find(post => {
    return post.node.originalId === pageContext.originalId
  })
  const currentPost = post?.node
  const nextPost = post?.next
  const previousPost = post?.previous
  const coverImage = getImage(currentPost?.coverImage)

  if (!currentPost) {
    return
  }

  return (
    <Template>
      <section className="container my-10 mx-auto px-3 md:my-20">
        <h1 className="pt-6 pb-14 text-center font-philosopher text-4xl">{currentPost.title}</h1>
        <ul className="mt-5 mb-3 flex flex-col space-y-1 md:flex-row md:flex-wrap md:space-y-0">
          <li className="v-separator-md flex text-xl">
            <Trans>Category</Trans>:&nbsp;
            {currentPost.category.map((category, index) => {
              const isLastCategory = index === currentPost.category.length - 1
              return <span key={index}>{`${category.name}${isLastCategory ? " " : ",\xa0"}`}</span> // spacebar after comma
            })}
          </li>
          {currentPost.author && (
            <li className="v-separator-md flex text-xl">
              <Trans>Author</Trans>:&nbsp;
              <span>{currentPost.author?.name}</span>
            </li>
          )}
          {currentPost.date && (
            <li className="v-separator-md text-xl">
              <Trans>Date</Trans>: <time>{currentPost.date}</time>
            </li>
          )}
        </ul>
        <GatsbyImage
          className="mb-14 mt-10 max-h-[300px] w-full"
          image={coverImage}
          alt={currentPost.coverImage.alt || ""}
        />
        <h1 className="mb-12 text-center font-philosopher text-4xl md:text-left">{currentPost.title}</h1>
        <div className="text-structured">
          <StructuredText
            data={currentPost.content}
            renderBlock={({ record }) => {
              switch (record.__typename) {
                case "DatoCmsImagesGallery":
                  return (<ImagesGallery content={record} />)
                default:
                  return null
              }
            }}
            renderInlineRecord={({ record, children, transformedMeta }) => {
              switch (record.__typename) {
                case "DatoCmsPage":
                  return (
                    <a {...transformedMeta} href={`/${record.slug}`}>
                      {children}
                    </a>
                  )
                case "DatoCmsPost":
                  return (
                    <a {...transformedMeta} href={`/${BLOG_SLUG}/${record.slug}`}>
                      {children}
                    </a>
                  )
                default:
                  return null
              }
            }}
            renderLinkToRecord={({ record, children, transformedMeta }) => {
              switch (record.__typename) {
                case "DatoCmsPage":
                  return (
                    <a {...transformedMeta} href={`/${record.slug}`}>
                      {children}
                    </a>
                  )
                case "DatoCmsPost":
                  return (
                    <a {...transformedMeta} href={`/${BLOG_SLUG}/${record.slug}`}>
                      {children}
                    </a>
                  )
                default:
                  return null
              }
            }}
          />
        </div>
        <div className="my-10 h-px w-full bg-gray-darken"></div>
        <div
          className={`mt-10 flex items-center ${
            previousPost ? "justify-between" : "justify-end"
          } space-x-6 md:space-x-0`}
        >
          {previousPost && (
            <Link className="flex w-1/2 space-x-2 md:w-max md:space-x-10" to={`/${BLOG_SLUG}/${previousPost.slug}`}>
              <ArrowLeft className="w-[50px]" />
              <div className="flex flex-col">
                <span className="hidden md:inline md:text-sm lg:text-base">
                  <Trans>Previous post</Trans>
                </span>
                <span className="font-philosopher text-sm line-clamp-2 md:text-xl lg:text-2xl xl:text-3xl">{`${previousPost.title}`}</span>
              </div>
            </Link>
          )}
          {nextPost && (
            <Link className="flex w-1/2 space-x-2 md:w-max md:space-x-10" to={`/${BLOG_SLUG}/${nextPost.slug}`}>
              <div className="flex flex-col ">
                <span className="hidden self-end md:inline md:text-sm lg:text-base">{<Trans>Next post</Trans>}</span>
                <span className="font-philosopher text-sm line-clamp-2 md:text-xl lg:text-2xl xl:text-3xl">{`${nextPost.title}`}</span>
              </div>
              <ArrowRight className="w-[50px]" />
            </Link>
          )}
        </div>
      </section>
    </Template>
  )
}

export const query = graphql`
  query PostQuery($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allDatoCmsPost(filter: { locale: { eq: $language } }) {
      edges {
        node {
          originalId
          slug
          title
          locale
          seoMetaTags {
            tags
          }
          category {
            name
          }
          coverImage {
            gatsbyImageData(placeholder: BLURRED)
            alt
            title
          }
          author {
            name
          }
          date
          content {
            value
            links {
              __typename
              ... on DatoCmsPage {
                id: originalId
                slug
                title
              }
              ... on DatoCmsPost {
                id: originalId
                slug
                title
              }
            }
            blocks {
              __typename
              ... on DatoCmsImagesGallery {
                id: originalId
                images {
                  alt
                  gatsbyImageData(placeholder: BLURRED, width: 1200, height: 1200)
                  title
                  originalId
                }
              }
            }
          }
        }
        next {
          slug
          title
        }
        previous {
          slug
          title
        }
      }
    }
  }
`

export default Post

export const Head = ({ data, pageContext }) => {
  const post = data.allDatoCmsPost.edges.find(post => {
    return post.node.originalId === pageContext.originalId
  })
  const currentPost = post?.node
  const remapedSeoTags = currentPost?.seoMetaTags.tags.map(tag => {
    return {
      tag: tag.tagName,
      attributes: tag.attributes,
      content: tag.content || null,
    }
  })
  return (
    <>
      {currentPost && renderMetaTags(remapedSeoTags)}
      <Theme />
    </>
  )
}
